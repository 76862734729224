<template>
  <VModalWidget :submit="submit" :dismiss="dismiss" :submitting="submitting" :submit-title="$t('labels.save')">
    <template v-slot:title>{{ $t('title') }}</template>
    <template v-slot:content>
      <FormErrors :show="showResourceErrors" :errors="resourceErrors" />
      <v-form>
        <CurrencyField
          v-model="form.meta.loan"
          :label="$t('labels.loanAmount')"
          :error-messages="loanErrors"
          :touch="$v.form.meta.loan.$touch"
        />
        <CurrencyField
          v-model="form.meta.monthly_payment"
          :label="$t('labels.monthlyPayment')"
          :error-messages="paymentErrors"
          :touch="$v.form.meta.monthly_payment.$touch"
        />
      </v-form>
    </template>
  </VModalWidget>
</template>

<script>
import { mapActions } from 'vuex'
import { required, decimal } from 'vuelidate/lib/validators'
import { VModalWidget, BaseForm, FormErrors } from '@argon/app/components'
import { NAMESPACE } from '@argon/evo/entities/store'
import DealInfoMixin from '../mixins/DealInfoModalMixin'
import { CurrencyField } from '../../components'

export default {
  name: 'LoanAmountsModal',
  extends: BaseForm,
  mixins: [DealInfoMixin],
  components: {
    VModalWidget,
    FormErrors,
    CurrencyField
  },
  validations: {
    form: {
      meta: {
        monthly_payment: { required, decimal },
        loan: { required, decimal }
      }
    }
  },
  data: () => ({
    form: {
      meta: {
        loan: '',
        monthly_payment: ''
      }
    }
  }),
  watch: {
    deal: function (v) {
      if (v) {
        this.form.meta.loan = v.meta.loan
        this.form.meta.monthly_payment = v.meta.monthly_payment
      }
    }
  },
  computed: {
    loanErrors() {
      const errors = []
      if (!this.$v.form.meta.loan.$dirty) return errors
      !this.$v.form.meta.loan.required && errors.push(this.$t('labels.required'))
      !this.$v.form.meta.loan.decimal && errors.push(this.$t('labels.invalid'))
      this.getServerErrors('loan', errors)
      return errors
    },
    paymentErrors() {
      const errors = []
      if (!this.$v.form.meta.monthly_payment.$dirty) return errors
      !this.$v.form.meta.monthly_payment.required && errors.push(this.$t('labels.required'))
      !this.$v.form.meta.monthly_payment.decimal && errors.push(this.$t('labels.invalid'))
      this.getServerErrors('monthly_payment', errors)
      return errors
    }
  },
  methods: {
    ...mapActions(NAMESPACE, ['patchEntity']),
    submit() {
      if (this.$v.form.$invalid) {
        return this.$v.form.$touch()
      }
      this.submitting = true
      let payload = {
        proxy: this.entityURL,
        url: this.baseURL,
        data: this.form
      }
      this.patchEntity(payload)
        .then((resp) => {
          this.dismiss()
        })
        .catch(this.setServerErrors)
        .finally(() => {
          this.submitting = false
        })
    }
  },
  created() {
    this.form.meta = {
      loan: this.boardItem.entity.meta.loan,
      monthly_payment: this.boardItem.entity.meta.monthly_payment
    }
  }
}
</script>

<i18n>
{
  "en": { "title": "Loan Amounts"},
  "fr": { "title": "Montants du prêt"}
}
</i18n>
